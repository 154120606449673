html, body {
  height: 100%;
}

#root {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App {
  padding: 25px;
}